import React from "react";
import { AppBtn } from "../components/ui/buttons";
import ErrorScreen from "../components/ui/error-screen";

const NotFound = () => {
  return (
    <ErrorScreen title="404: This page looks strange" message="Check the url and try again or:">
      <AppBtn href="/" className="mt-5" size="md">
        Go back home
      </AppBtn>
    </ErrorScreen>
  );
};

export default NotFound;
